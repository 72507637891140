<script>
    
    import { announcementDetails, userMessageDetail } from "@/api/my";
    export default {
        name:'MessageDetail',
        data() {
            return {
                content:'',
                title:'',

                header:{
                    mainTitle: getWord(['message', 'detail2']),
                },
                created_date:null,
            };
        },
        components:{
            'mt-header': app.Mint.Header,
            'mt-button': app.Mint.Button,
            Header: () => import('components/base/Header'),
        },
        methods: {
            left_click() {
                let hisNum = parseInt(window.history.length);
                    if(hisNum == 1){
                        this.$router.push('/home');
                    }else{
                        this.$router.go(-1);
                    }
            },
            //查看公告详情
            announcementDetails(id) {
                announcementDetails(id).then(result => {
                    if (result.data.code == "SUCCESS") {
                        this.title=result.data.result.title;
                        this.created_date=new Date(result.data.result.created_at*1000).toLocaleDateString().replace(/\//g, "-") + " " + new Date(result.data.result.created_at*1000).toTimeString().substr(0, 8)
                        this.content=result.data.result.content;
                        // this.CustomScroll(this.$refs.announcementDetail.id);
                    } else {
                        app.Mint.Toast(result.data.msg);
                    }
                });
            },
            //查看消息详情
            userMessageDetail(id) {
                userMessageDetail(id).then(result => {
                    if (result.data.code == "SUCCESS") {
                        this.title=result.data.result.title;
                        this.created_date=result.data.result.created_date;
                        this.content=result.data.result.content;
                        // this.CustomScroll(this.$refs.announcementDetail.id);
                    } else {
                        app.Mint.Toast(result.data.msg);
                    }
                });
            },
        },
        mounted() {
            this.$route.query.type==='1'
                ?this.announcementDetails(this.$route.query.id)
                :this.userMessageDetail(this.$route.query.id)
        }
    };
</script>
<template>
    <div id="announcement-detail" ref="announcementDetail" :class="_TEMPLATE">
        <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">
            <!-- <mt-header :title="title" style="height:0.88rem;font-size: 0.35rem;">
                <mt-button icon="back" slot="left" @click="left_click()"></mt-button>
            </mt-header> -->
            <Header
                :mainTitle="title"
                :backButton=false
            />
            <div class="main">            
                <div style="font-size:0.3rem;" v-html="content"></div>
            </div>
        </template>

        <template v-if="_TEMPLATE==='template-3'">
            <Header
                :mainTitle="header.mainTitle"
                :backButton=false
            />
            <h4 v-if="created_date">
                <b>{{title}}</b>
                <span>{{created_date}}</span>
            </h4>
            <content v-html="content"></content>
        </template>
    </div>
</template>
<style scoped lang='scss'>
#announcement-detail {
    height: 100%;
    display: flex;
    flex-direction: column;

    &.template-3 {

        h4 {
            font-size: .28rem;
            color: #B0B0B0;
            font-weight: normal;
            padding: .25rem;
            border-bottom: 1px solid #B0B0B0;

            b {
                color: #333333;
                font-weight: normal;
                display: block;
                font-size: .36rem;
                margin-bottom: .1rem;
            }
        }

        content {
            padding: .25rem;
            display: block;
            font-size: .3rem;
            color: #B0B0B0;
        }
    }

    .mint-header {

        /deep/ .mint-header-title {
            line-height: .88rem;
            width: 80%;
            margin: 0 auto;
        }
    }

    .main {
        overflow: auto;
        height: 100%;
        padding: .25rem;
    }
}
</style>